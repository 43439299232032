import 'twin.macro';

import { navigate } from '@reach/router';
import IconSelect from 'assets/svg/select-col.inline.svg';
import Button from 'components/Molecules/Button';
import { routes } from 'constants/routes';
import React from 'react';

export default function PanelSelect({ onClick }) {
	return (
		<Button panel onClick={onClick}>
			<IconSelect className='w-90 h-90' />
			<p className='font-semibold'>Faktoring zagraniczny</p>
		</Button>
	);
}

PanelSelect.defaultProps = {
	onClick: () => navigate(routes.SELECT_OFFER)
};
