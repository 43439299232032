import React from 'react';

export default function SubHeader({ Icon, title, iconSize, ...props }) {
	return (
		<div {...props} className='w-full flex items-center justify-start bg-light border-b border-grey-D px-15 py-12'>
			<Icon className='fill-current stroke-current text-dark' width={iconSize} height={iconSize} />
			<p className='text-13 font-semibold ml-07 text-grey-3'>{title}</p>
		</div>
	);
}

SubHeader.defaultProps = {
	iconSize: '1.3rem'
};
