import 'twin.macro';

import { navigate } from '@reach/router';
import IconLimit from 'assets/svg/limit-col.inline.svg';
import Button from 'components/Molecules/Button';
import { routes } from 'constants/routes';
import React from 'react';

export default function PanelLimit({ onClick }) {
	return (
		<Button panel onClick={onClick} tw='pb-05'>
			<IconLimit className='w-80 h-80' />
			<p className='font-semibold mt-05'>Faktoring krajowy</p>
		</Button>
	);
}

PanelLimit.defaultProps = {
	onClick: () => navigate(routes.LIMIT_OFFER)
};

